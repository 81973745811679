import React, { forwardRef, useRef, useState } from "react"
import PortStatus, { portTypes, portStatues } from "../../components/PortStatus"
import {
  Button,
  Col,
  Form,
  Grid,
  Heading,
  Input,
  Message,
  Row,
  Schema,
  SelectPicker,
  Stack,
  Text,
  toaster,
  Checkbox,
} from "rsuite"
import { useMutation } from "@tanstack/react-query"
import ConfirmDialog from "../../../../components/common/ConfirmDialog"
import { useDialog } from "../../../../hooks/useDialog"
import useFormSaveDisable from "../hooks/useFormSaveDisable"

const metaModel = Schema.Model({
  name: Schema.Types.StringType().isRequired("This field is required"),
  displayName: Schema.Types.StringType(),
  slug: Schema.Types.StringType().pattern(/^\S*$/, "Can not contain spaces"),
  description: Schema.Types.StringType(),
  type: Schema.Types.StringType()
    .isOneOf(portTypes, "Must be a real type")
    .isRequired("This field is required"),
  status: Schema.Types.StringType()
    .isOneOf(portStatues, "Must be a real status")
    .isRequired("This field is required"),
  excludeFromOverallTotals: Schema.Types.BooleanType(),
})

const TextArea = forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
))

const MetaDataSettingsForm = ({ selectedPortfolio }) => {
  const { onDialogClose, onDialogOpen, isOpen } = useDialog()
  const {
    onDialogClose: onExcludeDialogClose,
    onDialogOpen: onExcludeDialogOpen,
    isOpen: isExludeOpen,
  } = useDialog()
  const { onFormChange, isSaveDisabled, formValue } = useFormSaveDisable({
    id: selectedPortfolio.id,
    name: selectedPortfolio.name,
    displayName: selectedPortfolio.displayName,
    slug: selectedPortfolio.slug,
    type: selectedPortfolio.type,
    status: selectedPortfolio.status,
    description: selectedPortfolio.description || "",
    excludeFromOverallTotals:
      selectedPortfolio.excludeFromOverallTotals || false,
  })

  const [excludePortfolioValue, setExcludePortfolioValue] = useState(
    selectedPortfolio.excludeFromOverallTotals
  )

  const formRef = useRef()
  const mutation = useMutation({
    mutationKey: ["updatePortfolio"],
  })
  const [formError, setFormError] = useState({})

  const onConfirm = () => {
    mutation.mutate(formValue, {
      onSuccess: () => onDialogClose(),
    })
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error")
      toaster.push(<Message type="error">Form Error</Message>)
      return
    }
    onDialogOpen()
  }

  const onExcludeConfirm = () => {
    onFormChange({
      ...formValue,
      excludeFromOverallTotals: excludePortfolioValue,
    })
    onExcludeDialogClose()
  }

  const handleExcludeChange = (e) => {
    setExcludePortfolioValue(e.target.checked)
    onExcludeDialogOpen()
  }

  return (
    <Form
      ref={formRef}
      model={metaModel}
      formValue={formValue}
      onChange={onFormChange}
      onCheck={setFormError}
      fluid
    >
      <Grid fluid>
        <Row
          style={{
            marginBottom: "5px",
            marginTop: "10px",
          }}
        >
          <Col xs={24}>
            <Stack justifyContent="space-between" alignItems="center">
              <Heading level={5}>Portfolio Settings</Heading>
              <Form.Group>
                <Button
                  appearance="primary"
                  onClick={handleSubmit}
                  disabled={isSaveDisabled}
                >
                  Save
                </Button>
              </Form.Group>
            </Stack>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col xs={24}>
            <Stack>
              <Checkbox
                label="Exclude from overall totals"
                name="excludeFromOverallTotals"
                checked={excludePortfolioValue}
                onCheckboxClick={handleExcludeChange}
              />

              <Text>Exclude from overall totals</Text>
            </Stack>
            <Text size="sm" color="yellow">
              This checkbox will exclude this portfolio in overall totals
            </Text>
          </Col>
        </Row>
        <Row style={{ marginBottom: "10px" }}>
          <Col
            style={{ marginBottom: "5px" }}
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
          >
            <Form.Group controlId="type">
              <Form.Control
                style={{ width: "100%" }}
                name="type"
                label={
                  <span>
                    <PortStatus status={formValue.type} />
                    {` `}
                    <span>Type</span>
                  </span>
                }
                accepter={SelectPicker}
                data={portTypes.map((opt) => ({
                  label: opt,
                  value: opt,
                }))}
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            <Form.Group controlId="status">
              <Form.Control
                style={{ width: "100%" }}
                label={
                  <span>
                    <PortStatus status={formValue.status} />
                    {` `}
                    <span>Status</span>
                  </span>
                }
                name="status"
                accepter={SelectPicker}
                data={portStatues.map((opt) => ({
                  label: opt,
                  value: opt,
                }))}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            style={{ marginBottom: "10px" }}
          >
            {" "}
            <Form.Group controlId="name">
              <Form.ControlLabel>Name</Form.ControlLabel>
              <Form.Control name="name" />
              <Form.HelpText>Only used internally</Form.HelpText>
            </Form.Group>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={8}
            xl={8}
            style={{ marginBottom: "10px" }}
          >
            {" "}
            <Form.Group controlId="displayName">
              <Form.ControlLabel>Display Name</Form.ControlLabel>
              <Form.Control name="displayName" />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8}>
            {" "}
            <Form.Group controlId="slug">
              <Form.ControlLabel>Slug</Form.ControlLabel>
              <Form.Control
                disabled
                name="slug"
                errorMessage={formError.slug}
              />
              <Form.HelpText>
                Used for the URL and for retrieving a Portfolio
              </Form.HelpText>
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ marginBottom: "15px" }}>
          <Col xs={24}>
            <Form.Group controlId="description">
              <Form.ControlLabel>Description</Form.ControlLabel>
              <Form.Control
                name="description"
                label="Description"
                accepter={TextArea}
                rows={7}
              />
            </Form.Group>
          </Col>
        </Row>
      </Grid>
      <ConfirmDialog
        onClose={onDialogClose}
        onConfirm={onConfirm}
        isOpen={isOpen}
        isLoading={mutation.isLoading}
      >
        <Text>Are you sure you want save these changes?</Text>
      </ConfirmDialog>
      <ConfirmDialog
        onClose={onExcludeDialogClose}
        onConfirm={onExcludeConfirm}
        isOpen={isExludeOpen}
      >
        <Text>
          Are you sure you want to{" "}
          {excludePortfolioValue ? "exclude" : "include"}{" "}
          {selectedPortfolio.displayName} from overall totals?
        </Text>
      </ConfirmDialog>
    </Form>
  )
}

export default MetaDataSettingsForm
