import React from "react"
import { Heading, Loader, Stack, Tabs } from "rsuite"

import SaveToClipboard from "../../../components/common/SaveToClipboard"
import MetaDataSettingsForm from "../../Portfolios/Settings/components/MetaDataSettingsForm"
import NewAccountForm from "../../Portfolios/Settings/components/NewAccountForm"
import ModelDataSettingsForm from "../../Portfolios/Settings/components/ModelDataSettingsForm"
import LeverageSettingsForm from "../../Portfolios/Settings/components/LeverageSettingsForm"
import PortfolioHealthSettingsForm from "../../Portfolios/Settings/components/PortfolioHealthSettingsForm"
import FormModal from "../../../components/common/FormModal"
import DuplicatePortfolio from "../../Portfolios/Settings/controls/DuplicatePortfolio"
import CardContainer from "../../../components/common/CardContainer"

import styles from "./Managers.module.css"
import MilestoneForm from "../../Portfolios/Settings/components/MilestoneForm"

const PMPortfolioDetail = ({ isLoading, selectedPortfolio }) => {
  return (
    <CardContainer>
      {!!selectedPortfolio?.id ? (
        <>
          <Stack justifyContent="flex-end" style={{ marginBottom: "10px" }}>
            <Stack spacing={10}>
              <SaveToClipboard data={selectedPortfolio.id} label="Copy ID" />

              <FormModal
                buttonText="Duplicate"
                headerText="Duplicate Portfolio"
              >
                {({ handleClose }) => (
                  <DuplicatePortfolio
                    portfolio={selectedPortfolio}
                    handleClose={handleClose}
                  />
                )}
              </FormModal>
            </Stack>
          </Stack>

          <Tabs
            defaultActiveKey="1"
            appearance="subtle"
            className={styles.portfolioDetailTabs}
          >
            <Tabs.Tab eventKey="1" title="Portfolio Settings">
              <MetaDataSettingsForm
                key={selectedPortfolio.id}
                selectedPortfolio={selectedPortfolio}
              />
            </Tabs.Tab>
            <Tabs.Tab eventKey="2" title="Edit Accounts">
              {isLoading ? (
                <Loader content="Loading..." />
              ) : (
                <NewAccountForm
                  key={selectedPortfolio.id}
                  portfolioId={selectedPortfolio?.id}
                  accounts={selectedPortfolio.account}
                />
              )}
            </Tabs.Tab>
            <Tabs.Tab eventKey="3" title="Elements">
              {isLoading ? (
                <Loader content="Loading..." />
              ) : (
                <ModelDataSettingsForm
                  key={selectedPortfolio.id}
                  selectedPortfolio={selectedPortfolio}
                />
              )}
            </Tabs.Tab>
            <Tabs.Tab eventKey="4" title="Leverage">
              <LeverageSettingsForm portfolio={selectedPortfolio} />
            </Tabs.Tab>
            <Tabs.Tab eventKey="5" title="Health">
              <PortfolioHealthSettingsForm portfolio={selectedPortfolio} />
            </Tabs.Tab>
            <Tabs.Tab eventKey="6" title="Milestone">
              {isLoading ? (
                <Loader content="Loading..." />
              ) : (
                <MilestoneForm
                  key={selectedPortfolio.id}
                  portfolioId={selectedPortfolio?.id}
                  milestones={selectedPortfolio?.milestones}
                />
              )}
            </Tabs.Tab>
          </Tabs>
        </>
      ) : (
        <Stack alignItems="center" justifyContent="center">
          <Heading level={5}>Select a Portfolio</Heading>
        </Stack>
      )}
    </CardContainer>
  )
}

export default PMPortfolioDetail
