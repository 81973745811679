import { useMemo } from "react"
import useTradeSeverConfig from "./useTradeSeverConfig"

const auto_recon_fields = [
  "ticker",
  "expiry",
  "modelPosition",
  "accountPosition",
  "positionDifference",
  "cumModelContractsTraded",
  "cumAccountContractsTraded",
  "cumContractsTradedDiff",
]

const risk_fields = [
  "ticker",
  "expiry",
  "maintenance_margin_relative",
  "initial_margin",
  "margin_per_contract",
  "maintenance_margin",
]

const summaryFields = [
  "ticker",
  "percentagePnl",
  "absolutePnl",
  "endQuantity",
  "maintenance_margin_relative",
  "margin_per_contract",
  "maintenance_margin",
]

const columnKeyMap = (config) => ({
  AutoRecon: auto_recon_fields,
  Risk: risk_fields,
  Summary: summaryFields,
  Positions: config.map((column) => column.field),
  All: config.map((column) => column.field),
  null: config.map((column) => column.field),
})

const useTradeServerColumns = (config) => {
  const tradeServerMode = useTradeSeverConfig()
  const all_columns = config.map((column) => ({
    field: column.field,
    label: column.abbr_label,
  }))
  const columnMap = useMemo(() => columnKeyMap(config), [config])

  const columnKeys = useMemo(
    () => columnMap[tradeServerMode] || [],
    [columnMap, tradeServerMode]
  )

  const columns = useMemo(
    () => config.filter((column) => columnKeys.includes(column.field)),
    [config, columnKeys]
  )

  return { columns, tradeServerMode, all_columns }
}

export default useTradeServerColumns
