import {
  cell_format,
  cell_raw_format,
  color_by_diff_field,
  bool_bkgrn_color_change,
} from "../../../../../components/common/DataTable/utils"
import { COLORS, formats } from "../../../../../lib/utils"

const trade_server_data_config = [
  {
    label: "Ticker",
    abbr_label: "Ticker",
    field: "ticker",
    sortable: true,
    formatFn: (rowData) =>
      cell_format(formats.string)(
        rowData["ticker"] || rowData["symbol_ticker"] || rowData["symbol_root"]
      ),
    totals_field: "total_pnl",
    totals_format: cell_raw_format(() => "Total:"),
    width: 80,
    fixed: true,
  },
  {
    label: "Expiry",
    abbr_label: "Exp",
    field: "expiry",
    formatFn: color_by_diff_field("startQuantity")((v, diff) =>
      v === undefined || v === ""
        ? "--"
        : `${Math.abs(diff) > 0 ? `${diff}*` : ""}${formats.string(v)}`
    ),
    width: 80,
    fixed: true,
    sortable: true,
  },

  // {
  //   label: "% RPNL",
  //   abbr_label: "% Rel PNL",
  //   field: "relative_pnl",
  //   totals_field: "total_relative_pnl",
  //   totals_format: cell_format(formats.pct, true),
  //   formatFn: cell_format(formats.pct, true),
  //   width: 60,
  //   sortable: true,
  // },
  {
    label: "percentagePnl",
    abbr_label: "% PNL",
    field: "percentagePnl",
    totals_field: "total_percentagePnl",
    totals_format: cell_format((d) => formats.pct(d * 100), true),
    sortable: true,
    formatFn: cell_format((d) => formats.pct(d * 100, 2)),
    width: 60,
  },
  {
    label: "absolutePnl",
    abbr_label: "Pnl",
    field: "absolutePnl",
    sortable: true,
    formatFn: cell_format(formats.currency),
    totals_field: "total_absolutePnl",
    totals_format: cell_format(formats.currency),
    width: 90,
  },

  // {
  //   label: "𝚫 Ret",
  //   abbr_label: "𝚫 Ret",
  //   field: "price_change",
  //   formatFn: cell_format(formats.pct),
  //   width: 60,
  //   sortable: true,
  // },
  // {
  //   label: "𝚫 Prc",
  //   abbr_label: "𝚫 Prc",
  //   field: "price_diff",
  //   formatFn: cell_format(formats.float_2),
  //   width: 55,
  //   sortable: true,
  // },

  {
    label: "MM/PV",
    abbr_label: "MM/PV",
    field: "maintenance_margin_relative",
    totals_field: "total_maintenance_margin_relative",
    totals_format: cell_raw_format(formats.pct),
    formatFn: cell_raw_format(formats.pct),
    width: 55,
    sortable: true,
  },
  // {
  //   label: "$ PNL",
  //   abbr_label: "$ PNL",
  //   field: "absolute_pnl",
  //   totals_field: "total_absolute_pnl",
  //   totals_format: cell_format(formats.currency),
  //   formatFn: cell_format(formats.currency),
  //   width: 65,
  //   resizable: true,
  //   sortable: true,
  // },

  {
    label: "Start of Day Quantity",
    abbr_label: "SoD Qty",
    field: "startQuantity",
    sortable: true,
    formatFn: cell_format(formats.number),
    width: 55,
  },
  {
    label: "End of Day Quantity",
    abbr_label: "EoD Qty",
    field: "endQuantity",
    sortable: true,
    formatFn: cell_format(formats.number),
    width: 55,
  },
  // {
  //   label: "Quantity",
  //   abbr_label: "Qty",
  //   field: "quantity",
  //   formatFn: cell_format(formats.number),
  //   width: 45,
  //   sortable: true,
  // },
  // {
  //   label: "Multiplier",
  //   abbr_label: "Multiplier",
  //   field: "multiplier",
  //   formatFn: cell_format(formats.number),
  //   width: 60,
  //   sortable: true,
  // },
  // {
  //   label: "$ Value",
  //   abbr_label: "$V",
  //   field: "position_value",
  //   totals_field: "total_position_value",
  //   totals_format: cell_format(formats.us_abbr),
  //   formatFn: cell_format(formats.us_abbr),
  //   width: 75,
  //   sortable: true,
  // },
  // {
  //   label: "$ Price",
  //   abbr_label: "$P",
  //   field: "price",
  //   formatFn: cell_format(formats.price),
  //   width: 60,
  //   sortable: true,
  // },
  {
    label: "priceChange",
    abbr_label: "Price Change",
    field: "priceChange",
    sortable: true,
    formatFn: cell_format((d) => formats.pct(d * 100, 2)),
    width: 75,
  },
  {
    label: "priceDiff",
    abbr_label: "Price Diff",
    field: "priceDiff",
    sortable: true,
    formatFn: cell_format((d) => formats.price(d, 4)),
    width: 80,
  },
  {
    label: "startPriceRaw",
    abbr_label: "Start Price",
    field: "startPriceRaw",
    sortable: true,
    formatFn: cell_format(formats.price),
    width: 90,
  },
  {
    label: "endPriceRaw",
    abbr_label: "End Price",
    field: "endPriceRaw",
    sortable: true,
    formatFn: cell_format(formats.price),
    width: 90,
  },

  {
    label: "Init Margin Tot",
    abbr_label: "IM",
    field: "initial_margin",
    formatFn: cell_raw_format(formats.currency),
    width: 80,
    sortable: true,
  },
  {
    label: "Mtn Margin/Co",
    abbr_label: "MM/Co",
    field: "margin_per_contract",
    formatFn: cell_raw_format(formats.currency),
    width: 65,
    sortable: true,
  },
  {
    label: "M Margin Total",
    abbr_label: "MT",
    field: "maintenance_margin",
    totals_field: "total_maintenance_margin",
    totals_format: cell_raw_format(formats.us_abbr),
    formatFn: cell_raw_format(formats.us_abbr),
    width: 55,
    sortable: true,
  },

  //Auto Recon

  {
    label: "Mdl Position",
    abbr_label: "MP",
    field: "modelPosition",
    formatFn: bool_bkgrn_color_change(
      "different",
      COLORS.negative
    )(formats.number),
    width: 55,
    sortable: true,
  },
  {
    label: "Acc Position",
    abbr_label: "AP",
    field: "accountPosition",
    formatFn: bool_bkgrn_color_change(
      "different",
      COLORS.negative
    )(formats.number),
    width: 55,
    sortable: true,
  },
  {
    label: "Position Difference",
    abbr_label: "PD",
    field: "positionDifference",
    totals_field: "position_diff_abs",
    totals_format: cell_raw_format(formats.number),
    formatFn: bool_bkgrn_color_change(
      "different",
      COLORS.negative
    )(formats.number),
    width: 40,
    sortable: true,
  },
  {
    label: "Cum Model Contracts Traded",
    abbr_label: "CMCT",
    field: "cumModelContractsTraded",
    formatFn: bool_bkgrn_color_change(
      "changed",
      COLORS.warning
    )(formats.number),
    width: 40,
    sortable: true,
  },

  {
    label: "Cum Account Contracts Traded",
    abbr_label: "CACT",
    field: "cumAccountContractsTraded",
    formatFn: bool_bkgrn_color_change(
      "changed",
      COLORS.warning
    )(formats.number),
    width: 40,
    sortable: true,
  },

  {
    label: "Cum Contracts Traded Diff",
    abbr_label: "CCTD",
    field: "cumContractsTradedDiff",
    totals_field: "cum_abs_traded_diff",
    totals_format: cell_raw_format(formats.number),
    formatFn: bool_bkgrn_color_change(
      "changed",
      COLORS.warning
    )(formats.number),
    width: 60,
    sortable: true,
  },
]

export default trade_server_data_config
