import _ from "lodash"
import { generateClient } from "aws-amplify/api"
import { getInstrumentPnL } from "./graphql"

const client = generateClient()

export const GraphQL_API = (query, variables = {}) =>
  client.graphql({ query, variables })

export const getInstrumentPnLFn = async (input) => {
  try {
    const { data } = await GraphQL_API(getInstrumentPnL, {
      input,
    })
    return data.getInstrumentPnL
  } catch (e) {
    if (e.errors && e.errors.length) {
      throw new Error(e.errors[0].message)
    }
    throw e
  }
}

export const getInstrumentPnLQuery = (request) => ({
  queryKey: ["instrumentPnL", request],
  queryFn: () => getInstrumentPnLFn(request),
  enabled: true,
})

export const reduce_pnl_data = (data) => {
  const pnl_data = data.map((d) => ({
    [d.ticker]: d.pnlUsd,
    period: d.period,
  }))

  const grouped = _.groupBy(pnl_data, "period")
  // console.log(grouped)
  const group_reduced = _.reduce(
    grouped,
    (result, value) => {
      const value_reducer = _.reduce(
        value,
        (tot, item) => {
          tot = {
            ...tot,
            ...item,
          }
          return tot
        },
        {}
      )
      result = [...result, value_reducer]
      return result
    },
    []
  )
  const sorted = _.orderBy(group_reduced, ["period"])
  return sorted
}

export const active_field = (mode) => {
  let field
  switch (mode) {
    case "Positions":
      field = "value"
      break
    case "Summary":
      field = "percentagePnl"
      break
    case "Risk":
      field = "maintenance_margin_relative"
      break
    default:
      field = "value"
      break
  }
  return field
}

const DECAY = 0.4
const MULTIPLIER = 1 * 100
const OPACITY = 0.25

export const boolean_bkgrn = (value, perc, color) =>
  value
    ? `linear-gradient(90deg, ${color} ${
        Math.abs(perc) * DECAY
      }%, rgba(0,0,0,0) ${Math.abs(perc)}%)`
    : `black`

export const perc_bkgrn = (value) =>
  value
    ? value >= 0
      ? `linear-gradient(90deg, rgba(0,173,4,${OPACITY}) ${
          value * MULTIPLIER * DECAY
        }%, rgba(0,0,0,0) ${value * MULTIPLIER}%)`
      : `linear-gradient(90deg, rgba(225, 0, 0,${OPACITY}) ${
          Math.abs(value) * MULTIPLIER * DECAY
        }%, rgba(0,0,0,0) ${Math.abs(value) * MULTIPLIER}%)`
    : "black"

export const ts_row_style = ({
  showTradeServerColors,
  tradeServerMode,
  rowData,
  totals,
}) => {
  return {
    background:
      showTradeServerColors.value === "SHOW"
        ? rowData?.different
          ? boolean_bkgrn(
              rowData?.different,
              (Math.abs(rowData?.positionDifference) /
                totals?.position_diff_abs) *
                100,
              `rgba(251,138,4,${OPACITY})`
            )
          : rowData?.changed
          ? boolean_bkgrn(
              rowData?.changed,
              (Math.abs(rowData?.cumContractsTradedDiff) /
                totals?.cum_abs_traded_diff) *
                100,
              `rgba(251,242,4,${OPACITY})`
            )
          : perc_bkgrn(rowData?.percentagePnl * 100)
        : "black",
    height: "100%",
    opacity:
      tradeServerMode !== "Summary"
        ? !!rowData
          ? rowData?.isActive
            ? 1
            : 0.8
          : 1
        : 1,
  }
}
